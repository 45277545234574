/* eslint-disable */
import * as firebase from "firebase/app";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _sortBy from "lodash/sortBy";
import _filter from "lodash/filter";

// import { callFunctionsAPI } from '../../helpers/action';
// import { isArrayExists } from '../../helpers/validation';
import { getSnapshotDataBySchema } from "../../helpers/schemas";
import { hasLimitedAccess } from "../../helpers/auth";

import { logSchema } from "../../schemas/log";

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve, reject) => {});
};

/* helper end */

export const fb_getLatestUpdates = (callback, limits, authData) => {
    return new Promise((resolve, reject) => {
        let total = limits ? limits : 50,
            ref = firebase.database().ref("logs").orderByChild("logged_on").limitToLast(total);

        // for testing only
        // let total = 5000,
        //     ref = firebase.database().ref("logs").orderByChild("logged_on").limitToLast(total);

        if (authData && hasLimitedAccess(authData)) {
            ref = firebase.database().ref("logs").orderByChild("user").equalTo(authData.email).limitToLast(500);
        } // end - authData

        ref.on("value", (snapshot) => {
            var updates = [];

            if (snapshot.exists() && snapshot.hasChildren()) {
                snapshot.forEach((childSnapshot) => {
                    let update = getSnapshotDataBySchema(logSchema, childSnapshot);
                    updates.push(update);
                });
            } // end - results[2]

            // for testing only
            // sort by logged_on
            // updates = _filter(updates, (o) => {
            //     return o.action_type === "added" && o.type === "contact" && o.user_name === "System (DCL Sync)";
            // });
            // updates = _sortBy(updates, ["logged_on"]).reverse();

            callback(updates);
        });
    });
};
